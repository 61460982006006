import React, { type ReactElement } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes as Switch,
} from "react-router-dom";
import { NotFoundPage } from "../pages";
import { PAGE_ROUTES } from "./page-routes";

const Routes: React.FC = (): ReactElement => {

  return (
    <Router>
      <Switch>
        {PAGE_ROUTES.map(({ id, path, element }) => {
          return <Route key={id} path={path} element={element} />;
        })}
        <Route path="*" element={<NotFoundPage />} />
      </Switch>
    </Router>
  );
};

export default Routes;
