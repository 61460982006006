import React, { useEffect, useState } from "react";
import {
  ButtonCotinueStyled,
  ButtonSixStyled,
} from "../syledComponents/Buttons.styled";
import { Link } from "react-router-dom";
import { generateRandomUsername } from "../../utils/generateUsername";

type Props = {
  username: string;
  setUsername: (e: any) => void;
  usernameError: boolean;
  setUsernameError: (usernameError: boolean) => void;
};

const GenerateForm = ({
  username,
  setUsername,
  usernameError,
  setUsernameError,
}: Props) => {
  const [user, setUser] = useState("");

  const handleChange = (e: any) => {
    e.preventDefault();
    setUsernameError(false)
    setUser(e.target.value);
  };

  const submitForm = (event: any) => {
    event.preventDefault();
    if (user === '')
      setUsernameError(true)
    setUsername(user);
  };

  const createRandomUserName = (e: any) => {
    e.preventDefault();
    setUsername(generateRandomUsername());
  };

  return (
    <form
      className="w-full  sm:max-w-[50%]  mx-auto flex flex-col items-start text-start gap-2
      "
      action="
"
      onSubmit={submitForm}
    >
      <div className="w-full h-fit flex flex-col gap-8 items-start">
        <input
          className={`w-full ${usernameError && "alert"}`}
          id="username"
          name="username"
          type="text"
          value={user}
          placeholder="Create Your Avatar Name"
          onChange={handleChange}
        />

        <ButtonCotinueStyled className="" type="submit">
          Continue
        </ButtonCotinueStyled>
        <ButtonCotinueStyled className="border" onClick={createRandomUserName}>
          Skip
        </ButtonCotinueStyled>
        
      </div>
    </form>
  );
};

export default GenerateForm;
