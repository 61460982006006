import React, { ReactElement, useEffect, useRef, useState } from "react";
import {
  ButtonCotinueStyled,
  ButtonGradientStyled,
  ButtonOneStyled,
  ButtonSixStyled,
  GenderButtonStyled,
} from "../../../components/syledComponents/Buttons.styled";
import UploadIcon from "../../../assets/svgs/upload-cloud.svg";
import ImageIcon from "../../../assets/svgs/image.svg";
import AlertIcon from "../../../assets/svgs/alert-circle.svg";
import Star from "../../../assets/svgs/star.svg";
import ResetIcon from "../../../assets/svgs/reset.svg";

import { ReactComponent as ArrowRight } from "../../../assets/svgs/arrow-right.svg";
import { Link } from "react-router-dom";
import { UploadAvatar } from "../../../components/syledComponents/CreateStepsLayout.style";
import GenerationStepsLayout from "../CreateStepsLayout.page";
import { useGlobalMainContext } from "../../../contexts/globalMainContext";
import { CLoader } from "../../../components/custom";
import UploadSample from "../../../assets/images/sampleUpload.png";
import Tips from "../../../components/uploadAvatar/Tips";
import SampleTips from "../../../components/uploadAvatar/SampleTips";

type Props = {
  nextStep: (e: any) => void;
  file: File | undefined;
  setFile: (file: File) => void;
  fileError: boolean;
  setFileError: (fileError: boolean) => void;
  loading: boolean;
  username: string;
  selectedImage: string;
  setSelectedImage: (selectedImage: string) => void;
};

export const UploadAvatarStep = ({
  nextStep,
  file,
  setFile,
  fileError,
  setFileError,
  loading,
  username,
  selectedImage,
  setSelectedImage,
}: Props) => {
  // sample photos
  const [sampleModal, setSampleModal] = useState(false);

  const inputFile = useRef<HTMLInputElement>(null);

  const uploadFile = () => {
    inputFile.current?.click();
  };

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    if (event.target.files) {
      setFileError(false);
      var file = event.target.files[0];
      setFile(file);
      if (file) {
        setSelectedImage(URL.createObjectURL(file));
      } else {
        setSelectedImage("");
      }
    }
  };

  return (
    <>
      <SampleTips
        sampleModal={sampleModal}
        setSampleModal={setSampleModal}
        setSelectedImage={setSelectedImage}
      />
      <UploadAvatar>
        <div className="upload-part">
          <div
            className={`upload-box relative ${
              fileError ? "border-4 border-error-500" : ""
            }`}
          >
            <div className="upload-username-box">
              Your avatar name is: <span>{username}</span>
            </div>
            <img
              className="w-full h-full object-contain cursor-pointer"
              src={selectedImage || UploadSample}
              alt=""
              onClick={uploadFile}
            />
          </div>

          <div className="upload-buttons">
            {!selectedImage ? (
              <>
                <GenderButtonStyled
                  width="328px"
                  height="75px"
                  beforeOpacity="0"
                  onClick={() => setSampleModal(!sampleModal)}
                >
                  <div className="icon">
                    <img src={ImageIcon} alt="" />
                  </div>
                  Use sample photos
                </GenderButtonStyled>

                <GenderButtonStyled
                  width="328px"
                  height="75px"
                  beforeOpacity="0"
                  onClick={() => uploadFile()}
                >
                  <div className="icon">
                    <img src={UploadIcon} alt="" />
                  </div>
                  Upload your photo
                  <input
                    type={"file"}
                    accept={
                      "image/png,image/jpeg,image/jpg,image/bmp,image/webp"
                    }
                    ref={inputFile}
                    style={{ display: "none" }}
                    onChange={onChangeFile}
                  />
                </GenderButtonStyled>
              </>
            ) : (
              <>
                <GenderButtonStyled
                  width="200px"
                  height="75px"
                  beforeOpacity="0"
                  // onClick={() => uploadFile()}
                  onClick={() => setSelectedImage("")}
                >
                  <div className="icon">
                    <img src={ResetIcon} alt="" />
                  </div>
                  Re-Upload
                  <input
                    type={"file"}
                    accept={
                      "image/png,image/jpeg,image/jpg,image/bmp,image/webp"
                    }
                    ref={inputFile}
                    style={{ display: "none" }}
                    onChange={onChangeFile}
                  />
                </GenderButtonStyled>

                <ButtonGradientStyled
                  width="90%"
                  height="75px"
                  style={{ flex: 1 }}
                  onClick={nextStep}
                >
                  {loading ? (
                    <CLoader
                      central={true}
                      width={100}
                      height={40}
                      color={"white100"}
                    />
                  ) : (
                    <>
                      <div className="icon">
                        <img src={Star} alt="" />
                      </div>
                      <p> Generate Avatar</p>
                      <div className="icon">
                        <img src={Star} alt="" />
                      </div>
                    </>
                  )}
                </ButtonGradientStyled>
              </>
            )}
          </div>
        </div>
        <div className="upload-contents">
          <Tips />
        </div>
      </UploadAvatar>
    </>
  );
};
