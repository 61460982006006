import React, { useEffect, useState } from "react";
import { ReactComponent as ArrowRight } from "../../../assets/svgs/arrow-right.svg";
import { SelectStyle } from "../../../components/syledComponents/CreateStepsLayout.style";
import {
  ButtonGradientStyled,
  ButtonSixStyled,
} from "../../../components/syledComponents/Buttons.styled";
import { hairsList, HairsListType } from "./Hair/HairList";
import { ClothesList, ClothesListType } from "./Clothes/ClothesList";
import "@google/model-viewer/lib/model-viewer";
import HairStyle from "../../../assets/svgs/hair-style.svg";
import Clothes from "../../../assets/svgs/clothes.svg";
import ModelViewer from "../../../components/generate-avatar/model-viewer";
import ThreeScene from "../../../components/ThreeScene";
import { CLoader } from "../../../components/custom";
import Magic from "../../../assets/svgs/magic.svg";
import user from "../../../redux/slices/user";
import {
  generateAvatarCloth,
  generateAvatarHair,
} from "../../../api/avatar.api";

type Props = {
  nextStep: (e: any) => void;
  activeStep: string;
  hairName: string;
  setHairName: (hairName: string) => void;
  username: string;
  setLoading: (loading: boolean) => void;
  setClothName: (name: string) => void;
  clothName: string;
  loading: boolean;
  avatarUrl: string;
  setAvatarUrl: (avatar: string) => void;
};

export const SelectStyleStep = ({
  nextStep,
  activeStep,
  hairName,
  setHairName,
  username,
  loading,
  setLoading,
  avatarUrl,
  clothName,
  setClothName,
  setAvatarUrl,
}: Props) => {
  // hair list
  const [hairs] = useState<Array<HairsListType>>(hairsList);
  const [clothes] = useState<Array<ClothesListType>>(ClothesList);
  const [selectHairCode, setSelectHairCode] = useState<string>(
    hairsList[0].code
  );
  const [activeSelect, setActiveSelect] = useState<"hair" | "shirt">("hair");

  const handleChangeType = (type: "hair" | "shirt") => {
    setActiveSelect(type);
  };

  const handleGenerateHair = (
    event: any,
    username: string,
    hairName: string,
    clotName: string
  ) => {
    setLoading(true);

    setHairName(hairName);
    setClothName(clotName);

    if (hairName) {
      generateAvatarHair(username, hairName)
        .then((result) => {
          setLoading(false);
          if (result.success) {
            setAvatarUrl(result.data);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      generateAvatarCloth(username, clotName)
        .then((result) => {
          setLoading(false);
          if (result.success) {
            setAvatarUrl(result.data);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  //   useEffect(() => {
  //     nextStep(null);
  //   }, [hairName, clothName]);

  const [generateAvatar, setGenerateAvatar] = useState<any>(
    <div className={`style-box`}>
      <ModelViewer src={avatarUrl} username={username} />
      <ThreeScene />
    </div>
  );
  useEffect(() => {
    setGenerateAvatar(
      <div className={`style-box`}>
        <ModelViewer src={avatarUrl} username={username} />
        <ThreeScene />
      </div>
    );
  }, [avatarUrl]);

  return (
    <SelectStyle>
      <div className="style-part">{generateAvatar}</div>
      <div className="style-contents">
        <div className="style-tabs-part">
          <div
            className={`item ${activeSelect === "hair" ? "selected" : ""}`}
            onClick={() => handleChangeType("hair")}
          >
            <img src={HairStyle} />
            <p>Choose Your Hair Style</p>
          </div>
          <div
            className={`item ${activeSelect === "shirt" ? "selected" : ""}`}
            onClick={() => handleChangeType("shirt")}
          >
            <img src={Clothes} />
            <p>Choose Your Out-Fit</p>
          </div>
        </div>
        <div className="choose-style-part mb-auto">
          <h4 className="para">Choose Style</h4>
          {activeSelect === "hair" && (
            <div className={"hairs"}>
              <div className={"colors"}>
                {activeSelect === "hair" &&
                  hairs.map((hair) => (
                    <>
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "100%",
                          background: `#${hair.code}`,
                          cursor: "pointer",
                        }}
                        className={`${
                          selectHairCode === hair.code ? "selected" : ""
                        }`}
                        onClick={() => setSelectHairCode(hair.code)}
                      ></div>
                    </>
                  ))}
              </div>
            </div>
          )}

          <div className="choose-items">
            {activeSelect === "hair" &&
              selectHairCode &&
              hairs
                .filter((item) => item.code === selectHairCode)[0]
                .items.map((hair) => (
                  <div
                    className={`choose-item ${
                      hairName === hair.name ? "selected" : ""
                    } cursor-pointer }`}
                    onClick={(event) => {
                      handleGenerateHair(event, username, hair.name, "");
                    }}
                    style={{ position: "relative" }}
                  >
                    <img
                      className="w-full h-full object-cover"
                      src={hair.image}
                    />
                  </div>
                ))}
          </div>
          <div className="choose-items">
            {activeSelect === "shirt" &&
              clothes.map((cloth) => (
                <div
                  className={`choose-item cursor-pointer ${
                    clothName === cloth.name ? "selected" : ""
                  }`}
                  onClick={(event) => {
                    handleGenerateHair(event, username, "", cloth.name);
                  }}
                  style={{ position: "relative" }}
                >
                  <img
                    className="w-full h-full object-cover"
                    src={cloth.image}
                  />
                </div>
              ))}
          </div>
        </div>
        <div className="button-part items-center para">
          <ButtonGradientStyled
            width="200px"
            height="75px"
            onClick={(event: any) => {
              setHairName("");
              setClothName("");
              nextStep(event);
            }}
          >
            {loading ? (
              <CLoader
                central={true}
                width={100}
                height={40}
                color={"white100"}
              />
            ) : (
              <>
                <div className="icon">
                  <img src={Magic} alt="" />
                </div>
                <p> Generate</p>
              </>
            )}
          </ButtonGradientStyled>
        </div>
      </div>
    </SelectStyle>
  );
};
