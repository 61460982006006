// context.tsx
import React, { useState, createContext, useContext, useEffect } from "react";
import { useGlobalMainContext } from "./globalMainContext";
import {
  generateAvatar,
  generateAvatarCloth,
  generateAvatarHair,
} from "../api/avatar.api";

export const GlobalGenerateContext = createContext<any>(null);

const GlobalGenerateProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // main hook
  const { toasted, setToasted, signupTrigger } = useGlobalMainContext();

  // states
  const [file, setFile] = useState<File>();

  const [fileError, setFileError] = useState<Boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [usernameError, setUsernameError] = useState<boolean>(false);
  const [gender, setGender] = useState<string>("male");
  const [genderError, setGenderError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [avatarUrl, setAvatarUrl] = useState<string>("");
  const [hairName, setHairName] = useState<string>("");
  const [clothName, setClothName] = useState<string>("");

  const [activeStep, setActiveStep] = useState<
    "step-1" | "step-2" | "step-3" | "step-4" | "step-5" | "step-6"
  >("step-1");

  const alertTrigger = (text: string, type: "success" | "error" | "alert") => {
    setToasted((prev: {}) => ({
      ...prev,
      check: true,
      type: type,
      text: text,
    }));
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setToasted({ ...toasted, check: false });
    }, 2000);

    // Cleanup function
    return () => clearTimeout(timeoutId);
  }, [toasted]);

  //   steps trigger
  const handleContinue = () => {
    let goOn = true;

    if (activeStep === "step-4") {
      setUsernameError(false);
      setGenderError(false);
      setFileError(false);
      const errorMessages = [];

      if (username === "") {
        alertTrigger("please enter your username", "error");
        setUsernameError(true);
        goOn = false;
      }
      if (!file) {
        errorMessages.push("Please upload your avatar image.");
        alertTrigger("Please upload your avatar image.", "error");
        setFileError(true);
        goOn = false;
      }
      if (goOn) {
        setLoading(true);
        generateAvatar(username, gender, file)
          .then((result) => {
            if (result.success) {
              alertTrigger(
                "your avatar has been created successfully",
                "success"
              );
              setAvatarUrl(result.data);
              setActiveStep("step-5");
            } else {
              alertTrigger("Something went wrong.", "error");
            }
            setLoading(false);
          })
          .catch((error) => {
            console.log(error.response);
            if (error?.response?.data) {
              if (error?.response?.data?.detail) {
                alertTrigger(error?.response?.data?.detail.message, "error");
              } else {
                alertTrigger("Something went wrong.", "error");
              }
            } else {
              alertTrigger("Something went wrong.", "error");
            }
            setLoading(false);
          });
      }
    } else if (activeStep === "step-5") {
      setLoading(true);
      if (hairName) {
        generateAvatarHair(username, hairName)
          .then((result) => {
            if (result.success) {
              alertTrigger(
                "your avatar has been generated successfully",
                "success"
              );
              setAvatarUrl(result.data);
            } else {
              alertTrigger("Something went wrong.", "error");
            }
            setLoading(false);
          })
          .catch((error) => {
            if (error?.response?.data) {
              if (error?.response?.data?.detail) {
                alertTrigger(error?.response?.data?.detail.message, "error");
              } else {
                alertTrigger("Something went wrong.", "error");
              }
            } else {
              alertTrigger("Something went wrong.", "error");
            }
            setLoading(false);
          });
      } else if (clothName) {
        generateAvatarCloth(username, clothName)
          .then((result) => {
            if (result.success) {
              alertTrigger(
                "your avatar has been generated successfully",
                "success"
              );
              setAvatarUrl(result.data);
            } else {
              alertTrigger("Something went wrong.", "error");
            }
            setLoading(false);
          })
          .catch((error) => {
            if (error?.response?.data) {
              if (error?.response?.data?.detail) {
                alertTrigger(error?.response?.data?.detail.message, "error");
              } else {
                alertTrigger("Something went wrong.", "error");
              }
            } else {
              alertTrigger("Something went wrong.", "error");
            }
            setLoading(false);
          });
      } else {
        alertTrigger("your avatar has been generated successfully", "success");
        setActiveStep("step-6");
        setLoading(false);
        // generateAvatar(username, gender, file)
        //   .then((result) => {
        //     if (result.success) {
        //       alertTrigger(
        //         "your avatar has been generated successfully",
        //         "success"
        //       );
        //       setAvatarUrl(result.data);
        //       setActiveStep("step-6");
        //     } else {
        //       alertTrigger("Something went wrong.", "error");
        //     }
        //     setLoading(false);
        //   })
        //   .catch((error) => {
        //     if (error?.response?.data) {
        //       if (error?.response?.data?.detail) {
        //         alertTrigger(error?.response?.data?.detail.message, "error");
        //       } else {
        //         alertTrigger("Something went wrong.", "error");
        //       }
        //     } else {
        //       alertTrigger("Something went wrong.", "error");
        //     }
        //     setLoading(false);
        //   });
      }
    } else if (activeStep === "step-6") {
    }
  };

  const emptyAll = () => {
    setActiveStep("step-1");
    setGender("male");
    setUsername("");
    setClothName("");
    setHairName("");
    setLoading(false);
  };

  const contextValue = {
    activeStep,
    setActiveStep,
    file,
    setFile,
    fileError,
    setFileError,
    username,
    setUsername,
    usernameError,
    setUsernameError,
    genderError,
    setGenderError,
    gender,
    setGender,
    hairName,
    setHairName,
    handleContinue,
    loading,
    setLoading,
    avatarUrl,
    setAvatarUrl,
    alertTrigger,
    emptyAll,
    clothName,
    setClothName,
  };

  return (
    <GlobalGenerateContext.Provider value={contextValue}>
      {children}
    </GlobalGenerateContext.Provider>
  );
};

export const useGlobalGenerateContext = () => useContext(GlobalGenerateContext);

export default GlobalGenerateProvider;
