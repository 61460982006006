import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import styled from 'styled-components'
import React, { type ReactElement } from 'react'
import { breakpoints } from '../../config/global-styles'
import { isDesktop } from 'react-device-detect'

const ModalPaper = styled.div<{ width: string; background: string }>`
    width: ${({width}) => width + 'px'};
    background: ${({background}) => background || '#140E26'};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    border-radius: 30px;

    @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
        width: 100%;
        height: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;
        > .header {
            font-size: 0px;
        }
    }

    > .header {
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        padding: 15px;
        display: flex;

        .close {
            cursor: pointer;
            background: transparent;
            border: 2px solid #fff;
            transition: 0.3s ease;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            border-radius: 10px;
            padding: 6px;
            /* position: relative;
            z-index: 10; */

            &:hover {
                background: #fff;

                > svg {
                    path {
                        fill: #000;
                    }
                }
            }
        }

        .back-mobile {
            width: 100%;
            display: flex;
            align-items: start;
            justify-content: start;
            position: absolute;
            top: 0;
            left: 0;
            padding: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }
    }

    > .body {
        padding: 15px;

        div > div > h3 {
            font-size: 38px !important;
            font-weight: 600 !important;
        }

        .subtitle {
            margin-top: 20px;
            font-size: 20px !important;
        }
    }
`

interface Props {
  open: boolean
  onClose: (event: any, reason: 'backdropClick' | 'escapeKeyDown') => void
  title: string
  children: React.ReactNode
  width?: string
  background?: string
}

export function CModalTwo(props: Props): ReactElement {
  const {
    open,
    onClose,
    title,
    children,
    width = '600',
    background = '#140E26',
  } = props

  const handleClose = (): void => {
    onClose({}, 'backdropClick')
  }

  return (
    <Modal
      aria-labelledby="modal"
      aria-describedby="modal"
      open={open}
      onClose={onClose}
      closeAfterTransition
      style={{ overflowY: 'scroll' }}
    >
      <Fade in={open}>
        <ModalPaper width={width} background={background}>
          {title ? (
            <div className="header">
              {isDesktop ? (
                  <div className="" onClick={handleClose}>
                    <svg
                        onClick={handleClose} width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                      <path d="M19 5L5 19" stroke="white" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                      <path d="M5 5L19 19" stroke="white" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </svg>
                  </div>
              ) : (
                  <div className="back-mobile">

                    <svg
                        onClick={handleClose} width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                      <path d="M19 5L5 19" stroke="white" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                      <path d="M5 5L19 19" stroke="white" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </svg>


                  </div>
              )}
            </div>
          ) : null}
          <div className="body">{children}</div>
        </ModalPaper>
      </Fade>
    </Modal>
  )
}
