/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppSelector } from "../redux/hooks";
import moment from 'moment-timezone'

const GlobalContext = createContext({
  timeZone: "",
  locale: "",
  dateOption: null,
});

export const CeramicWrapper = ({ children }: any) => {
  const [timeZone, setTimeZone] = useState<string>("Euro/Germany");
  const [locale, setLocale] = useState<string>("de");
  const [dateOption, setDateOption] = useState<any>(null);
  useEffect(() => {
    const timezone = moment.tz.guess()
    const locale = moment.locale()

    setTimeZone(timezone);
    setLocale(locale);

    const options: any = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timezone: timezone
    };
    setDateOption(options);
  }, []);

  return (
    <GlobalContext.Provider
      value={{

        timeZone: timeZone,
        locale: locale,
        dateOption: dateOption,
      }}
    >
          {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
