import React, {ReactElement, useEffect, useRef, useState} from "react";
import {
    GenerateAvatar,
    SelectStyle,
} from "../../../components/syledComponents/CreateStepsLayout.style";
import {useGlobalMainContext} from "../../../contexts/globalMainContext";
import {ButtonTwoStyled, GenderButtonStyled} from "../../../components/syledComponents/Buttons.styled";
import ModelViewer from "../../../components/generate-avatar/model-viewer";
import UploadIcon from "../../../assets/svgs/upload-cloud.svg";
import ThreeScene from "../../../components/ThreeScene";
import {LoginButton} from "../../../components/auth/login-button";
import LoginWalletConnect from "../../../components/auth/login-wallet-connect";

type Props = {
    nextStep: (e: any) => void;
    username: string;
    avatarUrl: string;
};

export const GenerateAvatarStep = ({
                                       nextStep,
                                       username,
                                       avatarUrl,
                                   }: Props) => {
    const {setSignupTrigger} = useGlobalMainContext();

    const [generateAvatar, setGenerateAvatar] = useState<any>(
        <div className={`style-box`}>
            <ModelViewer src={avatarUrl} username={username}/>
            <ThreeScene/>
        </div>
    );
    useEffect(() => {
        setGenerateAvatar(
            <div className={`style-box`}>
                <ModelViewer src={avatarUrl} username={username}/>
                <ThreeScene/>
            </div>
        );
    }, [avatarUrl]);

    const download = () => {
        if (localStorage.getItem('token')) {
            window.open(avatarUrl, "_blank")
        } else {

        }
    }

    return (
        <SelectStyle>
            <div className="style-part">{generateAvatar}</div>
            <div className="style-contents" style={{alignItems: 'center', flexDirection: 'row'}}>
                <div className="download">
                    <div className="desc">
                        <p>kaboom!</p>
                        <div className="para2 lg:mb-[83px]">
                            congrats, <strong>{username}</strong>! your 3d avatar is ready!
                            Collaborate immersively using 3D avatars in embodia’s meeting rooms. Join as an avatar for
                            engaging teamwork!
                        </div>
                    </div>

                    <div style={{width: '260px', margin: '0 auto'}}>
                        {localStorage.getItem('token') ? (
                            <ButtonTwoStyled onClick={() => download()}>
                                <a className={"para3"}>
                                    <div className="icon">
                                        <img src={UploadIcon} alt=""/>
                                    </div>
                                    Download
                                </a>
                            </ButtonTwoStyled>
                        ) : (
                            <LoginButton text={'Sign up/in for Download'}/>
                        )}

                    </div>
                </div>
            </div>
        </SelectStyle>
    );
};
