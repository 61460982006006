import React from 'react'
import { CLoader } from '../../custom'
import { isDesktop } from '../../../utils/detect-screen'
import {LoginChildSec} from "../../syledComponents/LoginChild.style";
import Logo from "../../../assets/svgs/logo.svg";

type Props = {
  onClose?: () => void
}

export const Loading = ({onClose}: Props) => {
  return isDesktop() ? (
      <LoginChildSec>

          <img style={{width: '130px', margin: '0 auto'}} src={Logo} alt=""/>

          <div className="top-heading">
              <h3 className="title">Loading</h3>
              <div className="subtitle">It takes a few seconds, please wait.</div>
          </div>
          <div className="loading">
              <CLoader
                  width={50}
                  height={100}
                  central={true}
                  margin="0"
                  color="green100"
              />
          </div>
      </LoginChildSec>
  ) : (
      <LoginChildSec>
      <div className="top-heading">
        <h3 className="title">Loading</h3>
        <div className="subtitle">It takes a few seconds, please wait.</div>
      </div>
      <div className="loading">
        <CLoader
          width={50}
          height={100}
          central={true}
          margin="0"
          color="green100"
        />
      </div>
    </LoginChildSec>
  )
}
