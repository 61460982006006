import React, {useEffect, useState} from "react";
import UploadCheck from "../../assets/svgs/circle-check.svg";
import UploadClose from "../../assets/svgs/close.svg";
import HelpIcon from "../../assets/svgs/help-circle.svg";
import {GuidModal} from "../syledComponents/CreateStepsLayout.style";
import {ButtonSixStyled} from "../syledComponents/Buttons.styled";
import USER1 from "../../assets/images/user-1.jpeg";
import USER2 from "../../assets/images/user-2.jpeg";
import Step1Image from "../../assets/images/tips/step-1.png";
import Step2Image from "../../assets/images/tips/step-2.png";
import Step3Image from "../../assets/images/tips/step-3.png";
import Step4Image from "../../assets/images/tips/step-4.png";
import Step5Image from "../../assets/images/tips/step-5.png";
import {ReactComponent as StepWizard} from '../../assets/images/wizard-step.svg'

import {Link} from "react-router-dom";

type Props = {};

const Tips = (props: Props) => {
    const [modalOpen, setModalOpen] = useState(false);

    const [wizardSteps, setWizardSteps] = useState<'step-1' | 'step-2' | 'step-3' | 'step-4' | 'step-5'>('step-1')
    useEffect(() => {
        setWizardSteps('step-1')
    }, []);

    const getStepsClass = () => {
        if (wizardSteps === 'step-1') {
            return ''
        }else if (wizardSteps === 'step-2') {
            return 'circle-2'
        } else if (wizardSteps === 'step-3') {
            return 'circle-2 circle-3'
        } else if (wizardSteps === 'step-4') {
            return 'circle-2 circle-3 circle-4'
        } else if (wizardSteps === 'step-5') {
            return 'circle-2 circle-3 circle-4 circle-5'
        }
    }

    const getStepsTip = () => {
        if (wizardSteps === 'step-1') {
            return 'No face cover'
        }else if (wizardSteps === 'step-2') {
            return 'Exaggerated expression'
        } else if (wizardSteps === 'step-3') {
            return 'Too far or too close'
        } else if (wizardSteps === 'step-4') {
            return 'Different Angels'
        } else if (wizardSteps === 'step-5') {
            return 'Low quality & fuzzy photos'
        }
    }

    const getStepImage = () => {
        if (wizardSteps === 'step-1') {
            return Step1Image
        }else if (wizardSteps === 'step-2') {
            return Step2Image
        } else if (wizardSteps === 'step-3') {
            return Step3Image
        } else if (wizardSteps === 'step-4') {
            return Step4Image
        } else if (wizardSteps === 'step-5') {
            return Step5Image
        }
    }

    const handleGoNextWizardStep = () =>{
        if (wizardSteps === 'step-1') {
            setWizardSteps('step-2')
        } else if (wizardSteps === 'step-2') {
            setWizardSteps('step-3')
        } else if (wizardSteps === 'step-3') {
            setWizardSteps('step-4')
        }else if (wizardSteps === 'step-4') {
            setWizardSteps('step-5')
        } else if (wizardSteps === 'step-5') {
            setWizardSteps('step-1')
            setModalOpen(false)
        }
        getStepsClass()
    }

    return (
        <>
            <GuidModal
                visiblity={modalOpen ? "visible" : "hidden"}
                opacity={modalOpen ? "1" : "0"}
                pointer={modalOpen ? "auto" : "none"}
            >
                <div className={`upload-guid-modal`}>
                    <div className="modal-content">
                        <div className="bottom-content">
                            <div className="bottom-box">
                                <div className="bottom-item">
                                    <div className="content-box">
                                        <img
                                            className="w-full h-full object-cover"
                                            src={getStepImage()}
                                            alt=""
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-bottom">
                        <div className="btn">
                            <img src={UploadClose} alt=""/>
                            <span>{getStepsTip()}</span>
                        </div>
                        <div className="flex">
                            <div className={`svg ${getStepsClass()}`}>
                                <StepWizard/>
                            </div>
                            <ButtonSixStyled onClick={handleGoNextWizardStep}>
                                <Link to={""}>{wizardSteps !== 'step-5' ? 'Next' : 'Got it !'}</Link>
                            </ButtonSixStyled>
                        </div>

                    </div>
                </div>
            </GuidModal>

            <div
                className="text-part cursor-pointer"
            >
                <div className="guid-head capitalize">
                    <h4 className="para ">Upload guide</h4>
                    <div className="guid-button"
                         onClick={() => setModalOpen(!modalOpen)}>
                        <p className="">View guidelines</p>
                        <img src={HelpIcon} alt=""/>
                    </div>
                </div>
                <div className="guid-contents">
                    <div className="guid-item">
                        <img className="guid-img" src={USER1} alt=""/>
                        <div className="content-bottom">
                            <img src={UploadCheck} alt=""/>
                            <p className="para3">No coverage for face and neck</p>
                        </div>
                        <div className="content-bottom">
                            <img src={UploadCheck} alt=""/>
                            <p className="para3">Natural expressions</p>
                        </div>
                    </div>
                    <div className="guid-item">
                        <img className="guid-img" src={USER2} alt=""/>
                        <div className="content-bottom">
                            <img src={UploadCheck} alt=""/>
                            <p className="para3">One frontal half length photo</p>
                        </div>
                        <div className="content-bottom">
                            <img src={UploadCheck} alt=""/>
                            <p className="para3">Clear features</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Tips;
