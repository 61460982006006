import React from "react";
import {CButtonTwo} from "../../mui/ButtonTwo";
import {isDesktop} from "../../../utils/detect-screen";
import {LoginChildSec} from "../../syledComponents/LoginChild.style";
import Logo from "../../../assets/svgs/logo.svg";


type Props = {
    loading: boolean
    setLoginStep: (
        step: 'welcome' | 'signup-question' | 'signup' | 'none' | 'walletIframe'
    ) => void
    onClose: () => void
    setNewLogin: (newLogin: boolean) => void
}

export const SignupQuestion = ({
                                   loading,
                                   setLoginStep,
                                   setNewLogin,
                                   onClose,
                               }: Props) => {
    return (
        <LoginChildSec>
            <img style={{width: '130px', margin: '0 auto'}} src={Logo} alt=""/>

            <div className="top-heading">
                <h3 className="title">Join Our Community</h3>
                <div className="subtitle">
                    You don't have an account yet. Continue with this wallet to create an account?
                </div>
            </div>
            <div className="items-box-row">
                <CButtonTwo
                    color={'white'}
                    background={'#165ADE'}
                    hoverColor={'white'}
                    backgroundHover={'#165ADE'}
                    variant={'filled'}
                    fullWidth={true}
                    type={'button'}
                    size="s"
                    loading={false}
                    fontWeight={600}
                    fontSize={'12px'}
                    onClick={() => setLoginStep('signup')}
                    height={'40px'}
                    maxwidth={'238px'}
                    border={'#165ADE'}
                >
                    create account using current wallet
                </CButtonTwo>
                <CButtonTwo
                    color={'white'}
                    background={'#0D0D0D'}
                    backgroundHover={'transparent'}
                    variant={'filled'}
                    fullWidth={true}
                    type={'button'}
                    size="s"
                    fontWeight={600}
                    fontSize={'12px'}
                    maxwidth={'238px'}
                    loading={false}
                    onClick={() => setLoginStep('walletIframe')}
                    height={'40px'}
                    border={'#165ADE'}
                >
                    Try another wallet
                </CButtonTwo>
            </div>
        </LoginChildSec>
    )
};
