import React, { ReactElement, useEffect } from "react";
import { Page } from "../../components/structure";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../assets/svgs/arrow-right.svg";
import { Footer } from "../../components/structure/footer";
import {
  Problems,
  Ready,
  Technologies,
  TopSection,
  TopSectionMobile,
  WhatIsAvatia,
} from "./Home.page.style";
import { HeroSlider } from "../../components/HomePageComps/HeroSlider";
import { MobileHeroSlider } from "../../components/HomePageComps/MobileHeroSlider";
import { ButtonTwoStyled } from "../../components/syledComponents/Buttons.styled";
import { ProblemsSwiper } from "../../components/HomePageComps/ProblemsSwiper";

export function HomePage(): ReactElement {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Page title="Avatia">
      <TopSection>
        <HeroSlider />
      </TopSection>
      <TopSectionMobile>
        <MobileHeroSlider />
      </TopSectionMobile>

      <WhatIsAvatia>
        <h2>What Is Avatia?</h2>

        <p className="para2">
          Avatia is a cutting-edge platform for precision medicine that uses a
          person's customized avatar as a digital twin to provide a thorough and
          unique view of their current health status and past medical history.
          Avatia's digital health twins are virtual representations of a
          person's health that are created by combining data from many sources
          and applying sophisticated algorithms to provide a real-time
          simulation of a person's health state. Avatia seeks to provide clients
          with valuable information, personalized treatment regimens, and the
          ability to track illness progression, aging, and overall health.
        </p>

        <video src="./Avatia.mp4" controls />

        <ButtonTwoStyled>
          <Link to={"create-avatar"}>
            <div className="back-overlay"></div>
            <div className="text">
              <p className="para3">Get Started</p>
              <div className={"icon"}>
                <ArrowRight />
              </div>
            </div>
          </Link>
        </ButtonTwoStyled>
      </WhatIsAvatia>

      <Technologies>
        <h2>Avatia’s Technologies</h2>

        <div className="box">
          <div className="info">
            <h5>01</h5>
            <h3>3D Avatar</h3>
            <p className="para2">
              It is a realistic portrayal of the user's appearance, including
              bone structure, muscular system, and nerve system, as well as
              facial emotions, movements, and postures.{" "}
            </p>
          </div>

          <div className="img-box">
            <img
              src={require("../../assets/images/box-1.png")}
              alt="Avatia 3D Avatar"
            />
          </div>
        </div>

        <div className="box">
          <div className="info">
            <h5>02</h5>
            <h3>Personal Biodata​</h3>
            <p>
              Biodata can be collected using wearable devices and clinical
              equipment. Wearables measure biodata in real-world contexts using
              technologies including electrophysiology, microfluidics, and
              spectroscopy.
            </p>
          </div>
          <div className="img-box">
            <img
              src={require("../../assets/images/box-2.png")}
              alt="Avatia Personal Biodata​"
            />
          </div>
        </div>

        <div className="box" style={{ marginBottom: 0 }}>
          <div className="info">
            <h5>03</h5>
            <h3>Biodata Security</h3>
            <p>
              Standard frameworks for storing biodata on a decentralized
              security platform are critical to improving precision medicine and
              facilitating research efforts.
            </p>
          </div>
          <div className="img-box">
            <img
              src={require("../../assets/images/box-3.png")}
              alt="Avatia Biodata Security"
            />
          </div>
        </div>
      </Technologies>

      <Ready>
        <h2>Ready To Get Started?</h2>

        <p className="para">
          Avatia is a cutting-edge platform for precision medicine that uses a
          person’s customized avatar as a digital twin
        </p>
        <ButtonTwoStyled>
          <Link to={"create-avatar"}>
            <div className="back-overlay"></div>
            <div className="text">
              <p className="para3">Create Avatar</p>
              <div className={"icon"}>
                <ArrowRight />
              </div>
            </div>
          </Link>
        </ButtonTwoStyled>
      </Ready>

      <Problems>
        <h2>Problems Solved By Avatia</h2>

        <ProblemsSwiper />
      </Problems>

      <Footer />
    </Page>
  );
}
