export interface ClothesListType {
  name: string;
  image: string;
}

export const ClothesList: Array<ClothesListType> = [
  {
    name: "black_hoodie_and_pants.blend",
    image: require("../../../../assets/images/clothes/black-hoodie-and-pants.png"),
  },
  {
    name: "black_suit.blend",
    image: require("../../../../assets/images/clothes/black-suit.png"),
  },
  {
    name: "blue_hoodie1.blend",
    image: require("../../../../assets/images/clothes/blue-hoodie1.png"),
  },
  {
    name: "blue_suit.blend",
    image: require("../../../../assets/images/clothes/blue-suit.png"),
  },
  {
    name: "gray_coat.blend",
    image: require("../../../../assets/images/clothes/gray-coat.png"),
  },
  {
    name: "green_shirt1.blend",
    image: require("../../../../assets/images/clothes/green-shirt1.png"),
  },
  {
    name: "light_set.blend",
    image: require("../../../../assets/images/clothes/light-set.png"),
  },
  {
    name: "yellow_hoodie.blend",
    image: require("../../../../assets/images/clothes/yellow-hoodie1.png"),
  },
];
