import React from 'react'
import {CButtonTwo} from '../../mui/ButtonTwo'
import {UserModel} from '../../../models/user.model'
import {useAppDispatch} from '../../../redux/hooks'
import {set} from '../../../redux/slices/user'
import {isDesktop} from '../../../utils/detect-screen'
import {CSvgButton} from '../../mui/SvgButton'
import {ReactComponent as CloseLogin} from '../../../assets/svg/close-login.svg'
import {LoginChildSec} from "../../syledComponents/LoginChild.style";
import Logo from "../../../assets/svgs/logo.svg";

type Props = {
    loading: boolean
    onClose: () => void
    user?: UserModel
    wallet: string
    did: string
}

export const Welcome = ({loading, onClose, user, wallet, did}: Props) => {
    const dispatch = useAppDispatch()
    const handleOnClick = () => {
        dispatch(set({...user, wallet: wallet, did: did}))
        onClose()
    }

    return (
        <LoginChildSec>
            <img style={{width: '130px', margin: '0 auto'}} src={Logo} alt=""/>

            <div className="top-heading">
                <h3 className="title">Welcome back!</h3>
                <div style={{color: 'white'}} className="loading">
                    You've successfully logged into Avatia.
                </div>
            </div>
            <div className="loading">
                <CButtonTwo
                    color={'white'}
                    background={'#165ADE'}
                    backgroundHover={'#165ADE'}
                    variant={'filled'}
                    type={'button'}
                    size="s"
                    maxwidth={'70%'}
                    loading={loading}
                    fullWidth={true}
                    onClick={handleOnClick}
                    height={'40px'}
                    fontSize="14"
                    fontWeight={500}
                >
                    Start Exploring
                </CButtonTwo>
            </div>
        </LoginChildSec>

    )
}
