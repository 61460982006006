import styled from "styled-components";
import { globalViewPorts } from "../../config/global-styles";
import theme from "tailwindcss/defaultTheme";

export const CreateStepsBox = styled.div`
  background: ${({ theme }) => theme.black100};
  width: 100%;
  height: fit-content;
  overflow: hidden;

  > .upload-container {
    padding: 20px 94px;
    width: 100%;
    max-width: 1660px;
    margin: 0 auto;
    height: calc(100vh - 210px);
    @media screen and (max-height: 760px) {
      height: fit-content !important;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    div {
      > .steps {
        width: fit-content;
        /* min-height: 100px; */
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 957px;
        margin: 0 auto;
        gap: 40px;
        position: relative;
        overflow: hidden;

        > .line-bg {
          position: absolute;
          width: 100%;
          height: 2px;
          top: 50%;
          left: 0;
          z-index: 0;
          transform: translateY(-50%);
          background: ${({ theme }) => theme.gray100};
        }
        > .step-but {
          position: relative;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 20px;
          border-radius: 50px;
          background: ${({ theme }) => theme.black80};
          color: ${({ theme }) => theme.white100};
          border: 1px solid ${({ theme }) => theme.gray100};
        }
        > .step-but.active {
          border: 1px solid ${({ theme }) => theme.blue80};
        }
      }
    }

    @media (max-width: ${globalViewPorts.lg}) {
      padding: 80px 20px;
      gap: 40px;
      div {
        > .steps {
          gap: 20px;

          > .step-but {
            padding: 10px;
          }
        }
      }
    }
    @media (max-width: ${globalViewPorts.xs}) {
      padding: 40px 20px;
      gap: 40px;
      div {
        > .steps {
          gap: 20px;
          width: 100%;
          justify-content: space-between;
          > .step-but {
            padding: 10px;
          }
        }
      }
    }
  }

  > .footer {
    background: ${({ theme }) => theme.black100};
    padding: 20px;
    height: 90px;

    > p {
      font-family: "Poppins";
      text-align: center;
      color: ${({ theme }) => theme.white100};

      > a {
        font-family: "Poppins";
        text-align: center;
        color: ${({ theme }) => theme.white100};
      }
    }
  }
`;

export const UploadAvatar = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  gap: 2rem;

  .upload-part {
    height: 100%;
    width: 100%;
    flex-basis: 40%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;

    .upload-box {
      width: 544px;
      max-height: 517px;
      margin: 0 auto;
      border-radius: 40px;
      background-color: #1c1b1b;
      overflow: hidden;
      position: relative;
    }

    .upload-username-box {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 4;
      width: 100%;
      height: 67px;
      background-color: #27272797;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.white100};

      > span {
        border-radius: 20px;
        background: linear-gradient(
          to left,
          #f9ef00 0%,
          #f6ce00 50%,
          #fda700 100%
        );
        border: 1px solid #f6ce00;
        padding: 5px 14px;
        color: ${({ theme }) => theme.black100};
        margin-left: 10px;
      }
    }

    .upload-buttons {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
    }
  }

  .upload-contents {
    color: ${({ theme }) => theme.white100};
    width: 100%;
    height: 100%;
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4rem;
    position: relative;

    .title-part {
      display: flex;
      /* align-items: center; */
      flex-direction: column;
      gap: 1rem;
      text-transform: capitalize;

      .bottom-line {
        width: 100%;
        height: 1px;
        background: linear-gradient(
          90deg,
          rgba(39, 39, 39, 1) 0%,
          rgba(99, 99, 99, 1) 40%,
          rgba(39, 39, 39, 1) 100%
        );
        /* display: none; */
      }
    }

    .text-part {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      /* sample  */
      border-radius: 50px;
      padding: 30px;
      position: relative;

      .guid-head {
        display: flex;
        width: 100%;
        gap: 2rem;
        justify-content: space-between;
        align-items: center;
        border-bottom-style: solid;
        border-bottom-width: 3px;
        border-image: linear-gradient(
            to left,
            rgba(51, 51, 51, 1) 1%,
            rgba(155, 154, 154, 1) 50%,
            rgba(51, 51, 51, 1) 100%
          )
          100% 0 100% stretch;
        padding-bottom: 20px;

        .guid-button {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          p {
            font-size: 14px;
            /* font-weight: 500; */
            color: ${({ theme }) => theme.white100};
          }
        }
      }

      .guid-contents {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        align-items: center;
        width: 100%;
        gap: 1rem;
        margin-top: 20px;
        justify-content: center;

        .guid-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          gap: 1rem;
          margin: 0 auto;

          .guid-img {
            /* width: 154px; */
            width: 169px;
            height: 187px;
            @media (max-width: ${globalViewPorts.xl}) {
              width: 100%;
            }
            border-radius: 20px;
          }

          .content-bottom {
            width: 100%;
            display: flex;
            gap: 1rem;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-bottom: 10px;

            p {
              font-size: 12px;
            }
          }
        }
      }
    }

    .button-part {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .button {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        gap: 0.2rem;
        width: fit-content;
        background: ${({ theme }) => theme.blue20};
        border-radius: 50px;
        cursor: pointer;
        transition: 0.3s ease;

        &:hover {
          background: ${({ theme }) => theme.blue80};
        }
      }
    }
  }

  @media (max-width: ${globalViewPorts.lg}) {
    flex-direction: column;
    gap: 4rem;
    height: fit-content;
    .upload-part {
      gap: 40px;

      .upload-box {
        width: 100%;
        max-width: 280px;
        height: 273px;
        margin: 0 auto;
      }

      .upload-buttons {
        flex-direction: column;
        gap: 1rem;
      }
    }

    .upload-contents {
      .guidlines {
        display: none;
      }

      .title-part {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        gap: 1rem;

        .bottom-line {
          display: block;
        }
      }

      .button-part {
        justify-content: center;
      }
    }
  }

  @media (max-width: ${globalViewPorts.xs}) {
    flex-direction: column;
    gap: 4rem;
    height: fit-content;
    .upload-part {
      gap: 40px;

      .upload-box {
        width: 100%;
        max-width: 280px;
        height: 273px;
        margin: 0 auto;
      }

      .upload-buttons {
        flex-direction: column;
        gap: 1rem;
      }
    }

    .upload-contents {
      .guidlines {
        display: none;
      }

      .title-part {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        gap: 1rem;

        .bottom-line {
          display: block;
        }
      }

      .text-part {
        padding: 20px 10px;
        border-radius: 20px;

        .guid-head {
          display: flex;
          width: 100%;
          gap: 0rem;
          justify-content: space-between;
          align-items: center;

          .guid-button {
            p {
              font-size: 11px;
            }
          }
        }

        .guid-contents {
          grid-template-columns: repeat(2, 1fr);
          gap: 1rem;

          .guid-item {
            width: 100%;
            height: fit-content;
            overflow: hidden;
            border-radius: 20px;
            gap: 1rem;

            .guid-img {
              width: 100%;
              height: 150px;
              object-fit: cover;
            }

            .content-bottom {
              width: 100%;
              display: flex;
              gap: 0.2rem;
              align-items: center;
              justify-content: center;
              text-align: center;

              p {
                font-size: 10px;
              }
            }
          }
        }
      }

      .button-part {
        justify-content: center;
      }
    }
  }
`;

export const SelectStyle = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    overflow: hidden;
    gap: 4rem;

    .style-part {
        height: 100%;
        width: 100%;
        flex-basis: 40%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;

        .style-box {
            width: 100%;
            height: 595px;
            border-radius: 20px;
            background-color: #1c1b1b;
            overflow: hidden;

            .upload-username-box {
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 4;
                width: 100%;
                height: 67px;
                border-bottom-left-radius: 30px;
                border-bottom-right-radius: 30px;
                background-color: #27272797;
                display: flex;
                align-items: center;
                justify-content: center;
                color: ${({theme}) => theme.white100};

                > span {
                    border-radius: 20px;
                    background: linear-gradient(
                            to left,
                            #f9ef00 0%,
                            #f6ce00 50%,
                            #fda700 100%
                    );
                    border: 1px solid #f6ce00;
                    padding: 5px 14px;
                    color: ${({theme}) => theme.black100};
                    margin-left: 10px;
                }
            }
        }
    }

    .style-contents {
        color: ${({theme}) => theme.white100};
        width: 100%;
        height: 100%;
        flex-basis: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
        position: relative;
        overflow: auto;

        .download {
            background: #080807;
            padding: 15px;
            align-items: center;
            border-radius: 35px;

            .desc {
                p {
                    font-family: "Slackey", sans-serif !important;
                    font-weight: 400;
                    font-style: normal;
                    color: ${({theme}) => theme.white100};
                    text-align: center;
                    padding: 20px;
                    font-size: 48px;
                }

                .para2 {
                    font-size: 18px;
                    text-align: center;
                    color: ${({theme}) => theme.white100};
                }
            }
        }

        .style-tabs-part {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            justify-content: center;
            gap: 10px;
            background: ${({theme}) => theme.black70};
            border-radius: 50px;
            padding: 10px;

            > .item {
                background: linear-gradient(
                        180deg,
                        ${({theme}) => theme.black70} 0,
                        ${({theme}) => theme.black80} 100%
                );
                border-radius: 100px;
                display: flex;
                gap: 10px;
                padding: 10px 24px;
                align-items: center;
                justify-content: center;
                border: 1px solid ${({theme}) => theme.gray80};
                cursor: pointer;

                &.selected {
                    background: ${({theme}) => theme.black80};
                }

                > p {
                    font-size: 16px;
                    color: ${({theme}) => theme.gray90};
                }
            }
        }

        .choose-style-part {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 2rem;

            .hairs {
                display: flex;
                flex-direction: column;

                .colors {
                    display: flex;
                    flex-direction: column;
                    flex-flow: nowrap;
                    margin: 0 auto;
                    gap: 10px;

                    .selected {
                        border: 2px solid #969697;
                    }
                }
            }

            .choose-items {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                transition: transform 0.3s ease-in-out; /* Add transition for animation */
                gap: 2rem;

                .choose-item {
                    width: 145px;
                    height: 120px;
                    object-fit: cover;
                    @media (max-height: 700px) {
                        height: 145px;
                    }
                    overflow: hidden;
                    border-radius: 20px;
                    background: #383838;
                    border: 1px solid #505050;

                    &.selected {
                        border: 2px solid #165ade;
                    }
                }
            }
        }

        .button-part {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 0 auto;

            .button {
                display: flex;
                align-items: center;
                padding: 10px 20px;
                gap: 0.2rem;
                width: fit-content;
                background: ${({theme}) => theme.blue20};
                border-radius: 50px;
                cursor: pointer;
                transition: 0.3s ease;

                &:hover {
                    background: ${({theme}) => theme.blue80};
                }
            }
        }
    }

    @media (max-width: ${globalViewPorts.lg}) {
        flex-direction: column;
        height: fit-content;
        .style-part {
            .style-box {
                width: 100%;
                max-width: 280px;
                height: 595px;
                max-height: 272px;
                margin: 0 auto;
            }
        }

        .style-contents {
            overflow: auto;

            .style-tabs-part {
                gap: 1rem;
                justify-content: space-between;

                .style-selector {
                    cursor: pointer;
                    width: 45px;
                    height: 45px;
                    object-fit: contain;
                }
            }

            .choose-style-part {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .choose-items {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    /* grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); */
                    transition: transform 0.3s ease-in-out; /* Add transition for animation */
                    gap: 1rem;
                    justify-content: space-between;
                    width: 100%;

                    .choose-item {
                        width: 100%;
                        /* max-width: 91px; */
                        margin: 0 auto;
                        height: 91px;
                        border-radius: 10px;
                        background: #383838;
                        border: 1px solid #505050;
                    }
                }
            }

            .button-part {
                justify-content: center;
            }
        }
    }

    @media (max-width: ${globalViewPorts.xs}) {
        flex-direction: column;
        height: fit-content;
        .style-part {
            .style-box {
                width: 100%;
                max-width: 280px;
                height: 595px;
                max-height: 272px;
                margin: 0 auto;
            }
        }

        .style-contents {
            overflow: auto;

            .style-tabs-part {
                gap: 1rem;
                justify-content: space-between;

                .style-selector {
                    cursor: pointer;
                    width: 45px;
                    height: 45px;
                    object-fit: contain;
                }
            }

            .choose-style-part {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .choose-items {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    /* grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); */
                    transition: transform 0.3s ease-in-out; /* Add transition for animation */
                    gap: 1rem;
                    justify-content: space-between;
                    width: 100%;

                    .choose-item {
                        width: 100%;
                        /* max-width: 91px; */
                        margin: 0 auto;
                        height: 91px;
                        border-radius: 10px;
                        background: #383838;
                        border: 1px solid #505050;
                    }
                }
            }

            .button-part {
                justify-content: center;
            }
        }
    }
`;

export const GenerateAvatar = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  .generate-part {
    height: 100%;
    width: 100%;
    flex-basis: 40%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    .generate-box {
      width: 483px;
      height: 598px;
      border-radius: 20px;
      background-color: #1c1b1b;
      overflow: hidden;
    }
    .generate-buttons {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .generate-contents {
    color: ${({ theme }) => theme.white100};
    width: 100%;
    height: 100%;
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    position: relative;

    .title-part {
      display: flex;
      text-transform: capitalize;
      flex-direction: column;
      gap: 1rem;
      .bottom-line {
        width: 100%;
        height: 1px;
        background: linear-gradient(
          90deg,
          rgba(39, 39, 39, 1) 0%,
          rgba(99, 99, 99, 1) 40%,
          rgba(39, 39, 39, 1) 100%
        );
      }
    }
    .desc {
    }
    .text-part {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .button-part {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .button {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        gap: 0.2rem;
        width: fit-content;
        background: ${({ theme }) => theme.blue20};
        border-radius: 50px;
        cursor: pointer;
        transition: 0.3s ease;

        &:hover {
          background: ${({ theme }) => theme.blue80};
        }
      }
    }
  }

  @media (max-width: ${globalViewPorts.lg}) {
    height: fit-content;
    flex-direction: column;
    .generate-part {
      .generate-box {
        width: 280px;
        height: 272px;
        margin: 0 auto;
      }
    }
    .generate-contents {
      .title-part {
        text-align: center;
        align-items: center;
        justify-content: center;
        .bottom-line {
          width: 100%;
          height: 1px;
          background: linear-gradient(
            90deg,
            rgba(39, 39, 39, 1) 0%,
            rgba(99, 99, 99, 1) 40%,
            rgba(39, 39, 39, 1) 100%
          );
        }
      }
      .text-part {
        text-align: center;
        align-items: center;
      }
      .button-part {
        justify-content: center;
      }
    }
  }

  @media (max-width: ${globalViewPorts.xs}) {
    height: fit-content;
    flex-direction: column;
    .generate-part {
      .generate-box {
        width: 280px;
        height: 272px;
        margin: 0 auto;
      }
    }
    .generate-contents {
      .title-part {
        text-align: center;
        align-items: center;
        justify-content: center;
        .bottom-line {
          width: 100%;
          height: 1px;
          background: linear-gradient(
            90deg,
            rgba(39, 39, 39, 1) 0%,
            rgba(99, 99, 99, 1) 40%,
            rgba(39, 39, 39, 1) 100%
          );
        }
      }
      .text-part {
        text-align: center;
        align-items: center;
      }
      .button-part {
        justify-content: center;
      }
    }
  }
`;

export const GuidModal = styled.div<{
  visiblity: string;
  opacity: string;
  pointer: string;
}>`
  visibility: ${({ visiblity }) => visiblity};
  opacity: ${({ opacity }) => opacity};
  pointer-events: ${({ pointer }) => pointer};
  color: ${({ theme }) => theme.white100};
  position: fixed;
  height: 447px;
  overflow: hidden;
  max-width: 1000px;
  width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 9999;
  transition: all 0.3s ease;
  @media (max-width: ${globalViewPorts.lg}) {
    height: 500px;
    padding: 0 20px;
  }
  @media (max-width: ${globalViewPorts.sm}) {
    /* height: 500px; */
    padding: 0 0px;
  }

  .upload-guid-modal {
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.black100};
    border-radius: 30px;
    /* position: relative; */
    overflow-y: scroll;

    &::-webkit-scrollbar {
      /* Chrome, Safari, etc. */
      display: none;
    }

    .modal-bottom {
      margin-top: 25px;
      position: sticky;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 120px;
      background: var(--dark-gradient);
      background: #ffffff1f;
      backdrop-filter: blur(20px);
      align-items: center;
      justify-content: center;
      z-index: 20;


      > .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.2rem;
        background-color: ${({ theme }) => theme.dark100};
        height: 50px;
        width: 100%;
        padding: 10px;
        border-radius: 50px;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        ransition: transform 0.3s ease-in-out;

        > img {
          margin-top: 5px;
        }
      }

      > .flex {
        display: flex;
        justify-content: space-between;
        width: 80%;
        margin: 10px auto 0 auto;
        align-items: center;

        > .svg.circle-2 > svg > circle#circle-2 {
          fill: #0E21A0;
          stroke: #0E21A0;
        }

        > .svg.circle-3 > svg > circle#circle-3 {
          fill: #4E0EA0;
          stroke: #4E0EA0;
        }

        > .svg.circle-4 > svg > circle#circle-4 {
          fill: #830EA0;
          stroke: #830EA0;
        }

        > .svg.circle-5 > svg > circle#circle-5 {
          fill: #F60BB4;
          stroke: #F60BB4;
        }
      }
    }
  }

  .modal-header {
    width: 100%;
    height: 124px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 57px;
    @media (max-width: ${globalViewPorts.lg}) {
      padding: 0px 20px;
    }
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .top-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0 auto;


      gap: 2rem;

      .top-title {
      }

      .top-box {
        border: 1px solid #ffffff67;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        padding: 19px;

        .image-part {
          flex-basis: 50%;
          display: flex;
          gap: 2rem;

          .image-content {
            position: relative;
            background-color: ${({ theme }) => theme.dark80};

            .check-icon {
              position: absolute;
              top: 2px;
              right: 20px;
            }
          }
        }

        @media (max-width: ${globalViewPorts.lg}) {
          flex-direction: column;
          .image-part {
            flex-basis: 100%;
            width: 100%;

            .image-content {
              width: 100%;
            }
          }
        }

        > .text-sec {
          flex-basis: 50%;
          display: flex;
          flex-direction: column;
          background: transparent;
          gap: 1rem;

          p {
            font-size: 15px !important;
          }
        }
      }
    }

    /* line dvider goes here  */

    .bottom-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      gap: 2rem;

      .top-title {
      }

      .bottom-box,
      .bottom-box2 {
        width: 100%;
        margin: 0 auto;
        justify-content: space-between;
        gap: 2rem;

        @media (max-width: ${globalViewPorts.lg}) {
          grid-template-columns: repeat(1, 1fr);
        }

        .bottom-item {
          width: 380px;
          height: 300px;
          overflow: hidden;
          padding: 16px;
          border-radius: 30px;
          position: relative;
          margin: 0 auto;

          > .content-box {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 300px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: center;
            transition: 0.3s fade;
            img {
              width: 100%;
              height: 300px;
              object-fit: contain;
            }


          }
        }
      }

      .bottom-box2 {
        grid-template-columns: minmax(auto, 251px) minmax(auto, 251px) !important;
        justify-content: center !important;
        @media (max-width: ${globalViewPorts.lg}) {
          grid-template-columns: repeat(1, 1fr) !important;
        }
      }
    }
  }
}
`;

export const SamplePhotosModal = styled.div<{
  visiblity: string;
  opacity: string;
  pointer: string;
}>`
  visibility: ${({ visiblity }) => visiblity};
  opacity: ${({ opacity }) => opacity};
  pointer-events: ${({ pointer }) => pointer};
  color: ${({ theme }) => theme.white100};
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  > .upload-wrapper {
    position: relative;
    z-index: 10002;
    width: fit-content;
    max-width: 1000px;
    height: 779px;

    @media (max-width: ${globalViewPorts.lg}) {
    }
    @media (max-width: ${globalViewPorts.sm}) {
    }

    .upload-guid-modal {
      width: 100%;
      height: 100%;
      background-color: ${({ theme }) => theme.black100};
      border-radius: 30px;
      /* position: relative; */
      overflow-y: scroll;
      &::-webkit-scrollbar {
        /* Chrome, Safari, etc. */
        display: none;
      }
      .modal-bottom {
        position: sticky;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 81px;
        background: #ffffff1f;
        backdrop-filter: blur(20px);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 20;
      }
      .modal-header {
        width: 100%;
        height: 124px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 57px;
        @media (max-width: ${globalViewPorts.lg}) {
          padding: 0px 20px;
        }
      }
      .modal-content {
        padding: 0px 57px;
        display: grid;
        grid-template-columns: repeat(4, minmax(170px, 1fr));
        gap: 37px;
        .photo {
          width: 100%;
          /* max-width: 170px; */
          margin: 0 auto;
          height: 165px;
          overflow: hidden;
          border-radius: 15px;
          background: var(--dark-gradient);
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        @media (max-width: ${globalViewPorts.lg}) {
          padding: 0px 20px;
          grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
        }
        @media (max-width: ${globalViewPorts.sm}) {
          grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
        }
      }
    }
  }
`;
