import LoginWalletConnect from './login-wallet-connect'
import {ButtonTwoStyled} from "../syledComponents/Buttons.styled";
import React from "react";

interface Props {
    color?: string
    textColor?: string
    size?: 's' | 'm' | 'l'
    isOpen?: boolean
    text?: string
}

export function LoginButton(props: Props) {
    const {text = 'Sign up'} = props

    return (
        !localStorage.getItem('token') ?
            <LoginWalletConnect
                text={text}
            />
            :
            <ButtonTwoStyled onClick={() => {
                localStorage.removeItem('token')
            }}>
                <a className={"para3"}>
                    Logout
                </a>
            </ButtonTwoStyled>
    )
}
