export interface HairsListType {
    code: string;
    items: Array<{
        name: string;
        image: string;
        colorCode: string;
    }>;
}

export const hairsList: Array<HairsListType> = [
    {
        code: "3D260F",
        items: [
            {
                name: "classic_man_hair_brown_3D260F.glb",
                image: require("../../../../assets/images/hairs/classic_man_hair_brown_3D260F.png"),
                colorCode: "3D260F",
            },
        ],
    },
    {
        code: "836843",
        items: [
            {
                name: "classic_man_hair_brown_836843.glb",
                image: require("../../../../assets/images/hairs/classic_man_hair_brown_836843.png"),
                colorCode: "836843",
            },
        ],
    },
    {
        code: "382D21",
        items: [
            {
                name: "curl_man_brown_382D21.glb",
                image: require("../../../../assets/images/hairs/curl_man_brown_382D21.png"),
                colorCode: "382D21",
            },
        ],
    },
    {
        code: "C7C1A1",
        items: [
            {
                name: "curl_man_light_C7C1A1.glb",
                image: require("../../../../assets/images/hairs/curl_man_light_C7C1A1.png"),
                colorCode: "C7C1A1",
            },
        ],
    },
    {
        code: "4FAEA8",
        items: [
            {
                name: "woman_bob_blue_4FAEA8.glb",
                image: require("../../../../assets/images/hairs/woman_bob_blue_4FAEA8.png"),
                colorCode: "4FAEA8",
            },
            {
                name: "woman_classic_hair_blue_4FAEA8.glb",
                image: require("../../../../assets/images/hairs/woman_classic_hair_blue_4FAEA8.png"),
                colorCode: "4FAEA8",
            },
        ],
    },
    {
        code: "2E1A10",
        items: [
            {
                name: "woman_bob_brown_2E1A10.glb",
                image: require("../../../../assets/images/hairs/woman_bob_brown_2E1A10.png"),
                colorCode: "2E1A10",
            },
        ],
    },
    {
        code: "9C7C5A",
        items: [
            {
                name: "woman_bob_light_9C7C5A.glb",
                image: require("../../../../assets/images/hairs/woman_bob_light_9C7C5A.png"),
                colorCode: "9C7C5A",
            },
        ],
    },
    {
        code: "1C1914",
        items: [
            {
                name: "woman_classic_hair_dark_1C1914.glb",
                image: require("../../../../assets/images/hairs/woman_classic_hair_dark_1C1914.png"),
                colorCode: "1C1914",
            },
        ],
    },
    {
        code: "B2A190",
        items: [
            {
                name: "woman_classic_hair_light_B2A190.glb",
                image: require("../../../../assets/images/hairs/woman_classic_hair_light_B2A190.png"),
                colorCode: "B2A190",
            },
        ],
    },
];
